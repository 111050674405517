import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Input,
  Modal,
  Select,
	Tag,
  Typography,
  notification,
} from "antd";
import axios from "axios"
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import times from "../../times";
import GridFooter from "./GridFooter";
import GridHeader from "./GridHeader";
import SequenceItem from "./SequenceItem";
import SequencesWaitPage from "../common/SequencesWaitPage";
import Loader from "../common/Loader";

import { SaveOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { Search } = Input;

dayjs.extend(customParseFormat);

// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
	(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export default function Sequences({ Settings }) {
  const [divisionsState, setDivisionsState] = useState([]);
  const [sequencesState, setSequencesState] = useState([]);
  const [filteredSequences, setFilteredSequences] = useState([])
  const [currentDivision, setCurrentDivision] = useState('')
  const [generalEndStartTimes, setGeneralEndStartTimes] = useState({
	start: "07:00",
	end: "21:00",
  });
  const [searchState, setSearhState] = useState({
	searchQuery: '',
	isSearching: false
  })
  const [loading, setLoading] = useState(false)
  const [disableApplyBtn, setDisableApplyBtn] = useState(true)
  const variantsCHistorical = {
	visible: { opacity: 1, x: 0 },
	hidden: { opacity: 0, x: 100 },
  };

  useEffect(() => {
	getDivisions();
	// eslint-disable-next-line
  }, []);

  // API Calls
  const getDivisions = () => {
	setLoading(true)
	const url = `${Settings.redfieldDomain}${Settings.jikiSequencesDivisions}usa`
	axios.get(url, {auth: Settings.auth})
		.then(res => {
		  const preDivisions = Array.from(res.data.Divisions)
		  const newDivisions = preDivisions.map(division => {
			return {
			  label: division,
			  value: division
			}
		  })
		  setDivisionsState(newDivisions)
		  setLoading(false)
		})
		.catch(err => {
		  setLoading(false)
		  errorApiModal(err.message)
		})
  };
  const getSequencesByDivision = (value) => {
	setLoading(true)
	 const url = `${Settings.redfieldDomain}${Settings.jikiSequences}?division=${value}&organization=usa`
	 axios.get(url).then(res => {
	  setSequencesState(res.data.sequences)
         setDisableApplyBtn(false)
         setCurrentDivision(value)
	  notification.success({
		message: 'Éxito',
		description: 'Secuencias cargadas correctamente',
		duration: 4
	  })
	  setLoading(false)
	 }).catch(err => {
		setLoading(false)
		errorApiModal(err.message)
	  })
  };
  const setGeneralSchedules = () => {
    setDisableApplyBtn(true)
	const start = dayjs(generalEndStartTimes.start, "HH:mm");
	const end = dayjs(generalEndStartTimes.end, "HH:mm");
	if (start > end) {
	  initialDateIsGreater();
	  return;
	}
	if (end < start) {
	  finalDateIsLess();
	  return;
	}
	if (generalEndStartTimes.start === generalEndStartTimes.end) {
	  datesAreEqual();
	  return;
	}

	const timeTable = {
	  monday: {
		start: generalEndStartTimes.start,
		end: generalEndStartTimes.end,
	  },
	  tuesday: {
		start: generalEndStartTimes.start,
		end: generalEndStartTimes.end,
	  },
	  wednesday: {
		start: generalEndStartTimes.start,
		end: generalEndStartTimes.end,
	  },
	  thursday: {
		start: generalEndStartTimes.start,
		end: generalEndStartTimes.end,
	  },
	  friday: {
		start: generalEndStartTimes.start,
		end: generalEndStartTimes.end,
	  },
	  saturday: {
		start: generalEndStartTimes.start,
		end: generalEndStartTimes.end,
	  },
	  sunday: { start: "Closed", end: "Closed" },
	};
	const sequenceCopy = Array.from(sequencesState);
	const newSequenceTimes = sequenceCopy.map((sequence) => {
	  return {
		...sequence,
		sequenceTimes: timeTable,
	  };
	});
	setSequencesState(newSequenceTimes);
	notification.success({
	  message: "Éxito",
	  description: "Se han aplicado los Horarios Generales",
	  duration: 4,
	});
    setTimeout(() => {
        setDisableApplyBtn(false)
    }, 4000)
  };
  const saveWeeklyPlanningOfCampaignSequences = () => {
      const preSeq = Array.from(sequencesState)
      let preTimeTable;
      let isEndBefore;
      let isSame;
      preSeq.map(seq => {
          preTimeTable = seq.sequenceTimes
          const arr = Object.entries(preTimeTable)
          isEndBefore = arr.some(a => {
              return dayjs(a[1].end, "HH:mm").isBefore(dayjs(a[1].start, "HH:mm"))
          })
          isSame = arr.some(a => {
              return dayjs(a[1].end, "HH:mm").isSame(dayjs(a[1].start, "HH:mm"))
          })
          return true
      })
      if (isEndBefore) {
          initialDateIsGreater()
      }
      if (isSame) {
          datesAreEqual()
      }
	if (!isEndBefore && !isSame) {
	  // API Call Here
	  setLoading(true)
	   const url = `${Settings.redfieldDomain}${Settings.jikiSequences}?organization=usa`
	   const preSequences = Array.from(sequencesState)
	   const newSequences = preSequences.map(seq => {
		 return {
		   sequence: seq.name,
		   sequenceTimes: seq.sequenceTimes
		 }
	   })
	    axios.put(url, newSequences, {auth: Settings.auth}).then(res => {
		  notification.success({
		 	message: 'Éxito',
		 	description: 'La Planificación se guardo correctamente',
		 	duration: 4
		  })
            getSequencesByDivision(currentDivision)
		  setLoading(false)
	    }).catch(err => {
		  errorApiModal(err.message)
		  setLoading(false)
	    })
	}
  };
  // Help Functions
  const getGeneralStartTime = (value) => {
	setGeneralEndStartTimes({
	  ...generalEndStartTimes,
	  start: value,
	});
  };
  const getGeneralEndTime = (value) => {
	setGeneralEndStartTimes({
	  ...generalEndStartTimes,
	  end: value,
	});
  };
  const onSearch = (query) => {
	if (query === "") {
	  setSearhState({
		...searchState,
		isSearching: false
	  })
	  return;
	}

	setSearhState({
	  searchQuery: query,
	  isSearching: true
	})

	const filterSequences = Array.from(sequencesState).filter(seq => {
	  // return seq.sequence
	  return seq.name.toLowerCase().includes(query.toLowerCase())
	})
	setFilteredSequences(filterSequences)
  }
  const onSearchChange = (value) => {
	if (value === "") {
	  setSearhState({
		...searchState,
		isSearching: false
	  })
	  return;
	}

	setSearhState({
	  searchQuery: value,
	  isSearching: true
	})

	const filterSequences = Array.from(sequencesState).filter(seq => {
	  return seq.name.toLowerCase().includes(value.toLowerCase())
	})
	setFilteredSequences(filterSequences)
  }
  const initialDateIsGreater = () => {
	Modal.warning({
	  title: "Atención",
	  content: "La Hora Inicial no puede ser mayor a la Hora Final",
	  centered: true,
	});
  };
  const finalDateIsLess = () => {
	Modal.warning({
	  title: "Atención",
	  content: "La Hora Final no puede ser menor a la Hora Inicial",
	  centered: true,
	});
  };
  const datesAreEqual = () => {
	Modal.warning({
	  title: "Atención",
	  content: "Las Horas Inicial y Final no pueden ser iguales",
	  centered: true,
	});
  };
  const errorApiModal = (msg) => {
	Modal.error({
	  title: "Error",
	  content: msg,
	  centered: true,
	});
  };

  return (
	<motion.div
	  className="planifications-container"
	  variants={variantsCHistorical}
	  initial="hidden"
	  animate="visible"
	  transition={{
		duration: 1,
	  }}
	>
		<div
			className="title-subtitle-container"
			style={{ width: "100%", display: "flex" }}
		>
			<Title level={5} style={{ width: "50%" }}>Planificación Semanal de Secuencias de Campañas</Title>
			<Tag color="blue" style={{ display: "flex", alignItems: "center", fontWeight: 700 }}>US EAST</Tag>
		</div>
	  <div
		className="sections-container"
		style={{ width: "100%", height: "77vh", marginTop: "2rem" }}
	  >
		<div
		  className="general-schedules-input-container"
		  style={{
			display: "flex",
			alignItems: "flex-end",
			width: "100%",
			height: "5%",
			gap: "1rem",
		  }}
		>
		  <Card
			style={{
			  width: "25%",
			  height: "100%",
			  cursor: "default",
			  background: "#689de3",
			}}
			bodyStyle={{
			  padding: "2px",
			  textAlign: "center",
			}}
		  >
			<p style={{ fontWeight: "bold", marginBottom: "0em" }}>
			  Horarios Generales
			</p>
		  </Card>
		  <div
			className="start-end-inputs-container"
			style={{
			  width: "15%",
			  height: "100%",
			  display: "flex",
			  flexDirection: "row",
			  alignItems: "flex-end",
			  gap: "0.5rem",
			  marginBottom: "1.4rem",
			}}
		  >
			<div
			  className="general-start-time-container"
			  style={{
				width: "50%",
				height: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				textAlign: "center",
			  }}
			>
			  <p style={{ fontWeight: "bold", marginBottom: "0em" }}>Inicio</p>
			  <Card
				hoverable
				style={{
				  width: "100%",
				  height: "100%",
				  cursor: "default",
				  border: "2px solid #3874bd",
				}}
				bodyStyle={{
				  padding: "2px",
				  display: "flex",
				  alignItems: "center",
				  justifyContent: "center",
				}}
			  >
				<Select
				  id={"start"}
					showSearch
					options={times}
					onSelect={getGeneralStartTime}
					value={generalEndStartTimes.start}
					filterOption={filterOption}
				  size="small"
				  style={{ width: "100%" }}
				  bordered={false}
				/>
			  </Card>
			</div>
			<div
			  className="general-end-time-container"
			  style={{
				width: "50%",
				height: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				textAlign: "center",
			  }}
			>
			  <p style={{ fontWeight: "bold", marginBottom: "0em" }}>Fin</p>
			  <Card
				hoverable
				style={{
				  width: "100%",
				  height: "100%",
				  cursor: "default",
				  border: "2px solid #3874bd",
				}}
				bodyStyle={{
				  padding: "2px",
				  display: "flex",
				  alignItems: "center",
				  justifyContent: "center",
				}}
			  >
				<Select
				  id={"end"}
					showSearch
				  options={times}
				  onSelect={getGeneralEndTime}
					filterOption={filterOption}
				  style={{ width: "100%" }}
				  value={generalEndStartTimes.end}
				  size="small"
				  bordered={false}
				/>
			  </Card>
			</div>
		  </div>
		  <Button
			type="primary"
			size="middle"
			style={{ width: "12.5%", marginBottom: "0.1rem" }}
			onClick={() => setGeneralSchedules()}
            disabled={disableApplyBtn}
		  >
			Aplicar
		  </Button>
		</div>
		<div className="sequences-container" style={{ marginTop: "1rem" }}>
		  <div
			className="filters-container"
			style={{
			  width: "100%",
			  display: "flex",
			  justifyContent: "flex-end",
			  alignItems: "center",
			  gap: "0.5rem",
			  marginBottom: "2rem",
			}}
		  >
			Divisiones:
			<Select
			  id={"divisions"}
			  options={divisionsState}
			  placeholder="Seleccione división"
			  style={{ width: "12.5%" }}
			  // onChange={getSequencesByDivision}
			  onSelect={getSequencesByDivision}
			/>
			<Search
				placeholder="Buscar"
				style={{ width: "20%" }}
				onSearch={onSearch}
				onChange={(e) => onSearchChange((e.target.value))}
			/>
		  </div>
			{sequencesState.length > 0 && <GridHeader sequenceLength={sequencesState.length} />}
			{sequencesState.length > 0 && <GridFooter sequenceLength={sequencesState.length} />}
		  <div
			className="sequences-grid"
			style={{
				overflowY: "auto",
				width: "100%",
				height: "35vh",
				padding:"0.5rem"
			}}
		  >
			{searchState.isSearching ?
				filteredSequences.map(sequence => (
					<SequenceItem
						key={sequence.id}
						sequence={sequence}
						times={times}
						sequencesState={sequencesState}
						setSequencesState={setSequencesState}
						filteredSequences={filteredSequences}
						setFilteredSequences={setFilteredSequences}
						sequenceLength={filteredSequences.length}
					/>
				))
				: sequencesState.length > 0
			  ? sequencesState.map((sequence) => (
				  <SequenceItem
					key={sequence.id}
					sequence={sequence}
					times={times}
					sequencesState={sequencesState}
					setSequencesState={setSequencesState}
					filteredSequences={filteredSequences}
					setFilteredSequences={setFilteredSequences}
					sequenceLength={filteredSequences.length}
				  />
				))
			  : <SequencesWaitPage />}
		  </div>
		  {sequencesState.length > 0 && (
			<div
			  className="save-btn-container"
			  style={{
				width: "100%",
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-end",
				marginTop: "2rem",
			  }}
			>
			  <Button
				type="primary"
				icon={<SaveOutlined />}
				onClick={() => saveWeeklyPlanningOfCampaignSequences()}
			  >
				Guardar
			  </Button>
			</div>
		  )}
		</div>
	  </div>
	  {loading ? <Loader /> : null}
	</motion.div>
  );
}
