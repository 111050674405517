const Settings = {}

// IMPORTANTE cambiar el dominio de redfieldpush a produccion o dev segun sea el caso:
// https://gepp.rfpush.dev.kranon.cloud -> production url
// https://lab.gepp.rfpush.kranon.cloud -> dev url
const redfieldpushdomain = "https://gepp.rfpush.kranon.cloud";

//const domain = "https://gepp.dev.kranon.cloud";
const domain = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

Settings.staticMedia = '/static/img/'
Settings.redfieldDomain = domain
Settings.redfieldPushDomain = redfieldpushdomain
Settings.redfieldLocalDomain = 'https://gepp.redfield.kranon.cloud'
Settings.loginPath = '/auth/login'
Settings.tokiReport = '/toki/report/range/'
Settings.tokiCampaignsReal = '/toki/report/format/'
Settings.tokiAgents = '/toki/report/agents/range/'
Settings.tokiAgentsReal = '/toki/report/agents/realtime'
Settings.tokiKeyAccountsReport = '/toki/report/cuentaclave/range'
Settings.tokiKeyAccountsReal = '/toki/report/cuentaclave/realtime'
Settings.tokiDigitalsReport = '/toki/report/digital/range'
Settings.tokiDigitalsReal = '/toki/report/digital/realtime'
Settings.tokiReportGeneral = '/toki/report/detalle/general'
Settings.tokiReportInteracciones = '/toki/report/detalle/interaccion'
Settings.meraAuto = '/mera/contactlist/load?clear='
Settings.hanaCampaigns = '/hana/campaigns/date'
Settings.hanaKeyAccountsCamps = '/hana/queues/date'
Settings.hanaContactsCampsQuery = '?division=whatsapp'
Settings.hanaContactsCampsQueryCtc = '?division=contacts'
Settings.hanaContactsCampsQueryPoc = '?division=digital-details-poc'
Settings.hanaStatus = '/hana/campaigns/status'
Settings.jikiSequencesDivisions = '/jiki/sequences/schedules/divisions?organization='
Settings.jikiSequences = '/jiki/sequences/schedules'
Settings.tokiReportAgentlessRange = '/toki/report/agentless/range'
Settings.tokiReportAgentlessFormat = '/toki/report/agentless/format'

export default Settings